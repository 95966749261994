<template>
  <div>
    <base-section id="endusermyservices-account">
      <v-container>
        <!--
        <kdw-Statusbar-card
          v-model="snackbarUpdate"
          :snackbar-timeout="snackbarTimeout"
          :snackbar-message="consentOK"
        />
        -->
        <kdw-dialog-booking
          v-model="dialogOnlineBooking"
          :service-id="ServiceIDforOnlineBooking"
          :answer-id="answerId"
          :event="'euAnswerUpdateFromBooking;' + answerId"
          @euAnswerUpdateFromBooking="euAnswerUpdateFromBooking($event)"
        />
        <kdw-dialog-review
          v-model="dialogReview"
          from="EU"
          :answer-id="dialogReviewAnswerId"
          :review-id="dialogReviewReviewId"
          :sp-id="dialogReviewspId"
          :service-id="dialogReviewServiceId"
          :request-nb="dialogReviewRequestNb"
          :answer-nb="dialogReviewAnswerNb"
          @resetDialogReview="resetDialogReview"
          @updateRequestListEU="updateRequestListEU($event)"
        />
        <v-row justify="space-between">
          <v-col cols="12" md="12">
            <base-info-card :title="$t('RequestEU.mainTitle')" />
            <!---   Some buttons to drive the end user    ----->
            <v-container>
              <v-row justify="space-between">
                <kdw-btn
                  id="search_btn"
                  color="primary"
                  @click="navigateSearch()"
                >
                  {{ $t("RequestEU.search") }}
                </kdw-btn>
                <kdw-btn
                  v-if="consentOK !== ''"
                  id="switch_account"
                  color="primary"
                  @click="navigateMyAccount()"
                >
                  {{ $t("sideMenuEU.account") }}
                </kdw-btn>
              </v-row>
            </v-container>
            <!---   Radio button to sort the list    ----->
            <v-container>
              <v-radio-group
                v-if="request.requests.length !== 0"
                v-model="sortingChoice"
                row
                @change="sortingChoiceChanged"
              >
                <v-radio
                  :label="$t('RequestEU.ascendingDate')"
                  value="ascendingDate"
                />
                <v-radio
                  :label="$t('RequestEU.descendingDate')"
                  value="descendingDate"
                />
                <v-radio :label="$t('RequestEU.category')" value="category" />
              </v-radio-group>
            </v-container>
            <v-container>
              <v-row>
                <!-- V-DIALOG DISPLAY Description or legal points -------------------------------------------->
                <v-dialog v-model="dialogDescription" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <base-info-card
                        :title="dialogDescriptionType"
                        color="primary"
                      />
                    </v-card-title>
                    <v-card-text
                      v-if="questionItem !== null"
                      style="white-space: normal"
                    >
                      {{ dialogDescriptionTest }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <kdw-btn text @click="cancelDialogDescription">
                        {{ $t("button.cancel") }}
                      </kdw-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- V-DIALOG QUESTIONS -------------------------------------------->
                <kdw-dialog-question-results
                  v-model="dialogQuestions"
                  :question-item="questionItem"
                />
                <!-- V-DIALOG DELETE -------------------------------------------->
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-text>
                      <kdw-avatar-card
                        :icon="'mdi-help-circle-outline'"
                        :outlined="false"
                        :title="getDeleteDialogText()"
                        color="transparent"
                        horizontal
                        space="0"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <kdw-btn
                        id="deleteDialogButGenericCancelButton"
                        text
                        @click="cancelDelete"
                      >
                        {{ $t("button.cancel") }}
                      </kdw-btn>
                      <v-spacer />
                      <kdw-btn
                        id="deleteDialogButGenericConfirmButton"
                        @click="deleteRequestConfirmed"
                      >
                        {{
                          dialogDeleteType === 1 || dialogDeleteType === 2
                            ? $t("button.deleteRequestEU")
                            : $t("button.validateQuestion")
                        }}
                      </kdw-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- V-DIALOG Card info SP-------------------------------------------->
                <kdw-dialog-infoeusp
                  v-model="dialogCardInfo"
                  :business-info-cpy-name="businessInfoCpyName"
                  :business-info="businessInfo"
                  :url-logo="businessInfo[3].urlLogo"
                />
                <!-- V-DIALOG ressource info -------------------------------------------->
                <kdw-dialog-info-resource
                  v-model="dialogResourceInfo"
                  :title="$t('RequestEU.resourceName')"
                  :callout="resourceFistLastName"
                />
              </v-row>
              <v-row
                v-for="(myRequest, r) in request.requests"
                :key="myRequest.title"
                dense
              >
                <v-col>
                  <!-- VCARD REQUEST -------------------------------------------->
                  <v-card
                    :disabled="requestDisabled(myRequest)"
                    elevation="11"
                    class="rounded-xl"
                    color="#fcfaff"
                    shaped
                  >
                    <v-system-bar color="secondary" dark />
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          :class="
                            requestDisabled(myRequest)
                              ? 'text-h5'
                              : 'primary--text text-h5'
                          "
                          style="white-space: normal"
                        >
                          {{ myRequest.serviceName }}
                        </v-list-item-title>
                        <v-list-item-title
                          v-if="myRequest.projectName !== null"
                          :class="
                            requestDisabled(myRequest)
                              ? 'text-h6'
                              : 'primary--text text-h6'
                          "
                          style="white-space: normal"
                        >
                          {{ myRequest.projectName }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{
                            myRequest.isFixedPrice === true
                              ? formatFixedPrice(
                                  myRequest.isFixedPrice,
                                  myRequest.answers[0].servicePrice,
                                )
                              : formatFixedPrice(myRequest.isFixedPrice)
                          }}, {{ myRequest.createdAt }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle>{{
                          myRequest.stateRequest.getCurrentStateTranslated()
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle
                          >{{ $t(myRequest.catName) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-show="myRequest.appointmentId !== ''"
                          class="secondary--text"
                        >
                          <v-icon color="secondary"> mdi-calendar </v-icon>
                          <b> {{ myRequest.appointmentDateTime }} </b>
                          <!-- We propose a menu related to the appointment -->
                          <v-menu offset-y>
                            <template #activator="{ on, attrs }">
                              <v-btn
                                color="secondary"
                                icon
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item>
                                <v-list-item-title>
                                  <v-btn
                                    @click="openDialogResourceInfo(myRequest)"
                                  >
                                    <v-icon center> mdi-account </v-icon>
                                    {{ $t("RequestEU.resource") }}
                                  </v-btn>
                                </v-list-item-title>
                                <v-list-item-title>
                                  <v-btn
                                    @click="deleteAppointment(myRequest, r)"
                                  >
                                    <v-icon center> mdi-delete </v-icon>
                                    {{ $t("RequestEU.delAppoint") }}
                                  </v-btn>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-show="
                            myRequest.appointmentId === '' &&
                            myRequest.onlineBooking === true
                          "
                        >
                          <v-btn
                            color="secondary"
                            rounded
                            x-small
                            @click="addAppointment(myRequest)"
                          >
                            <v-icon left> mdi-calendar </v-icon>
                            {{ $t("RequestEU.addAppoint") }}
                          </v-btn>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="myRequest.isFixedPrice === false">
                      <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                      </v-list-item-icon>
                      <v-list-item-subtitle
                        >{{
                          myRequest.isProposalSP === true
                            ? $t("RequestEU.proposalSPYes")
                            : $t("RequestEU.proposalSPNo")
                        }}
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-card-actions>
                      <v-btn
                        :id="'extendAnswerButton' + r"
                        :color="requestDisabled(myRequest) ? '' : 'primary'"
                        text
                        @click="myRequest.show = !myRequest.show"
                      >
                        {{ myRequest.answers.length }} {{ $t("button.answer") }}
                      </v-btn>
                      <v-spacer />
                      <v-btn icon @click="myRequest.show = !myRequest.show">
                        <v-icon>
                          {{
                            myRequest.show
                              ? "mdi-chevron-up"
                              : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </v-btn>
                    </v-card-actions>
                    <!----  Expand of the V-CARD ------------------------->
                    <v-expand-transition>
                      <div v-show="myRequest.show">
                        <v-divider />

                        <v-card-text v-if="myRequest.answers.length > 0">
                          <v-row>
                            <!----  This is the header in full screen with the text on top -->
                            <v-col class="hidden-sm-and-down">
                              {{ $t("RequestEU.serviceProvider") }}
                            </v-col>
                            <v-col>
                              {{ $t("RequestEU.state") }}
                            </v-col>
                            <v-col>
                              {{ $t("RequestEU.price") }}
                            </v-col>
                            <v-col
                              v-if="myRequest.isFixedPrice === false"
                              class="hidden-sm-and-down"
                            >
                              {{ $t("RequestEU.quotation") }}
                            </v-col>
                            <v-col class="hidden-sm-and-down">
                              {{ $t("RequestEU.note") }}
                            </v-col>
                            <v-col class="hidden-sm-and-down">
                              {{ $t("RequestEU.legal") }}
                            </v-col>
                            <v-col class="hidden-sm-and-down">
                              {{ $t("RequestEU.action") }}
                            </v-col>
                          </v-row>
                          <v-row
                            v-for="(myAnswer, i) in myRequest.answers"
                            :key="i"
                          >
                            <!-- this is the text for each answer proposed  -->
                            <v-col class="hidden-sm-and-down">
                              <span :id="'SPPartialIdEUButtonDesktop' + r + i">
                                {{ "SP" + myAnswer.serviceProvider.namecpy }}
                              </span>
                            </v-col>
                            <v-col>
                              <span :id="'stateStatusEUButtonDesktop' + r + i">
                                {{
                                  myAnswer.state !== undefined
                                    ? convertStateName(
                                        myAnswer.state.currentState,
                                      )
                                    : ""
                                }}
                              </span>
                            </v-col>
                            <v-col
                              :class="
                                requestDisabled(myRequest)
                                  ? ''
                                  : 'primary--text'
                              "
                            >
                              <span :id="'priceEUButtonDesktop' + r + i"
                                ><strong>{{
                                  formatServicePriceValueInt(
                                    myAnswer.servicePrice,
                                  )
                                }}</strong></span
                              >
                            </v-col>
                            <v-col
                              v-if="myRequest.isFixedPrice === false"
                              class="hidden-sm-and-down"
                            >
                              <v-icon
                                center
                                :disabled="
                                  myAnswer.state.isEUQuotationButtonDisabled()
                                "
                                @click="
                                  openQuotation(myAnswer.quotation, myRequest)
                                "
                              >
                                mdi-file-pdf
                              </v-icon>
                            </v-col>
                            <v-col class="hidden-sm-and-down">
                              <v-icon
                                center
                                :disabled="
                                  myAnswer.state.isEUQuotationButtonDisabled()
                                "
                                @click="
                                  openDialogDescription(
                                    myAnswer.noteCustomer,
                                    1,
                                  )
                                "
                              >
                                mdi-comment-eye-outline
                              </v-icon>
                            </v-col>
                            <v-col class="hidden-sm-and-down">
                              <v-icon
                                center
                                :disabled="
                                  myAnswer.state.isEUQuotationButtonDisabled()
                                "
                                @click="
                                  openDialogDescription(
                                    myAnswer.serviceLegaldescription,
                                    2,
                                  )
                                "
                              >
                                mdi-comment-flash-outline
                              </v-icon>
                            </v-col>
                            <v-col class="hidden-sm-and-down">
                              <!-- v-if="(myRequest.isFixedPrice === false) && !(myAnswer.state.isEUDeleteDisabled())"
                              v-if=" (myAnswer.state.isEUDeleteDisabled())"
                              -->
                              <v-tooltip
                                v-if="myAnswer.state.isEUDeleteDisplayed()"
                                bottom
                              >
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    :id="
                                      'deleteAnswerUserButtonDesktop' + r + i
                                    "
                                    center
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="deleteAnswer(myAnswer, i, r)"
                                  >
                                    mdi-delete
                                  </v-icon>
                                </template>
                                <span>{{
                                  $t("tooltip.EURefuseProposal")
                                }}</span>
                              </v-tooltip>
                              <v-tooltip
                                v-if="
                                  !myAnswer.state.isValidationButtonDisabled()
                                "
                                bottom
                              >
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    :id="
                                      'validateAnswerUserButtonDesktop' + r + i
                                    "
                                    center
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="validateAnswer(myAnswer, i, r)"
                                  >
                                    mdi-check-bold
                                  </v-icon>
                                </template>
                                <span>{{
                                  $t("tooltip.EUValidateSPProposal")
                                }}</span>
                              </v-tooltip>
                              <!-- v-if="myAnswer.state.isCardAccountButtonDisplayed()" removed from v-tooltip - Display the SP infos all the time -->
                              <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    :id="
                                      'openDialogCardInfoEUButtonDesktop' +
                                      r +
                                      i
                                    "
                                    center
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="openDialogCardInfo(myAnswer)"
                                  >
                                    mdi-card-account-details
                                  </v-icon>
                                </template>
                                <span>{{ $t("tooltip.cardVisitSP") }}</span>
                              </v-tooltip>
                              <v-tooltip
                                v-if="
                                  myAnswer.state.isCardReviewButtonDisplayedEU()
                                "
                                bottom
                              >
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    :id="'openDialogReview' + r + i"
                                    center
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="
                                      openDialogReview(
                                        myAnswer,
                                        myRequest.serviceId,
                                        r,
                                        i,
                                      )
                                    "
                                  >
                                    mdi-star
                                  </v-icon>
                                </template>
                                <span>{{ $t("tooltip.review") }}</span>
                              </v-tooltip>
                            </v-col>
                            <!----- We try to put a new menu for MOBILES -->
                            <v-col class="hidden-md-and-up">
                              <v-menu offset-y>
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    color="primary"
                                    icon
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-if="
                                      myRequest.isFixedPrice === false &&
                                      !myAnswer.state.isEUQuotationButtonDisabled()
                                    "
                                    key="2"
                                  >
                                    <v-list-item-title>
                                      <v-btn
                                        @click="
                                          openQuotation(
                                            myAnswer.quotation,
                                            myRequest,
                                          )
                                        "
                                      >
                                        <v-icon center> mdi-file-pdf </v-icon>
                                        {{ $t("RequestEU.quotation") }}
                                      </v-btn>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="
                                      !myAnswer.state.isEUQuotationButtonDisabled()
                                    "
                                    key="3"
                                  >
                                    <v-list-item-title>
                                      <v-btn
                                        @click="
                                          openDialogDescription(
                                            myAnswer.noteCustomer,
                                            1,
                                          )
                                        "
                                      >
                                        <v-icon center>
                                          mdi-comment-eye-outline
                                        </v-icon>
                                        {{ $t("RequestEU.note") }}
                                      </v-btn>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="
                                      !myAnswer.state.isEUQuotationButtonDisabled()
                                    "
                                    key="4"
                                  >
                                    <v-list-item-title>
                                      <v-btn
                                        @click="
                                          openDialogDescription(
                                            myAnswer.serviceLegaldescription,
                                            2,
                                          )
                                        "
                                      >
                                        <v-icon center>
                                          mdi-comment-flash-outline
                                        </v-icon>
                                        {{ $t("RequestEU.legal") }}
                                      </v-btn>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="
                                      myRequest.isFixedPrice === false &&
                                      !myAnswer.state.isEUDeleteDisabled()
                                    "
                                    key="5"
                                  >
                                    <v-list-item-title>
                                      <v-btn
                                        :id="
                                          'deleteAnswerUserButtonMobile' + r + i
                                        "
                                        @click="deleteAnswer(myAnswer, i, r)"
                                      >
                                        <v-icon center> mdi-delete </v-icon>
                                        {{ $t("RequestEU.delete") }}
                                      </v-btn>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="
                                      !myAnswer.state.isValidationButtonDisabled()
                                    "
                                    key="6"
                                  >
                                    <v-list-item-title>
                                      <v-btn
                                        :id="
                                          'validateAnswerUserButtonMobile' +
                                          r +
                                          i
                                        "
                                        color="primary"
                                        @click="validateAnswer(myAnswer, i, r)"
                                      >
                                        <v-icon center color="primary">
                                          mdi-check-bold
                                        </v-icon>
                                        {{ $t("RequestEU.validprop") }}
                                      </v-btn>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <!-- v-if="myAnswer.state.isCardAccountButtonDisplayed()" removed from v-list-item - Display the SP infos all the time -->
                                  <v-list-item key="7">
                                    <v-list-item-title>
                                      <v-btn
                                        :id="
                                          'openDialogCardInfoEUButtonMobile' +
                                          r +
                                          i
                                        "
                                        color="sea"
                                        @click="openDialogCardInfo(myAnswer)"
                                      >
                                        <v-icon center>
                                          mdi-card-account-details
                                        </v-icon>
                                        {{ $t("RequestEU.infoprov") }}
                                      </v-btn>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="
                                      myAnswer.state.isCardReviewButtonDisplayedEU()
                                    "
                                    key="8"
                                  >
                                    <v-list-item-title>
                                      <v-btn
                                        :id="'openDialogReview' + r + i"
                                        color="sea"
                                        @click="
                                          openDialogReview(
                                            myAnswer,
                                            myRequest.serviceId,
                                            r,
                                            i,
                                          )
                                        "
                                      >
                                        <v-icon center> mdi-star </v-icon>
                                        {{ $t("RequestEU.infoReview") }}
                                      </v-btn>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item key="1">
                                    <v-list-item-title>{{
                                      "SP" +
                                      myAnswer.serviceProvider.id.substring(29)
                                    }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                    <v-card-actions>
                      <v-divider />
                    </v-card-actions>
                    <v-card-actions>
                      <v-btn
                        v-show="!requestDisabled(myRequest)"
                        color="secondary"
                        rounded
                        @click="deleteRequest(myRequest)"
                      >
                        <v-icon left> mdi-delete </v-icon>
                        {{ $t("button.deleteRequestEU") }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        v-show="!requestDisabled(myRequest)"
                        v-if="myRequest.isFixedPrice === false"
                        color="secondary"
                        rounded
                        @click="StopStartSPProposal(myRequest)"
                      >
                        <v-icon left> mdi-account-group </v-icon>
                        {{
                          myRequest.isProposalSP === true
                            ? $t("button.stopRequestEU")
                            : $t("button.startRequestEU")
                        }}
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        v-show="!requestDisabled(myRequest)"
                        v-if="myRequest.qna !== null"
                        color="secondary"
                        rounded
                        @click="displayQuestions(myRequest)"
                      >
                        <v-icon left> mdi-help </v-icon>
                        {{ $t("button.question") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { RequestService } from "@/services/Request";
import { Logger, Storage } from "aws-amplify";
import { downloadBlob } from "@/helpers/GenericFunctionsS3Download";
import { FILEHEADERQUOTATION } from "@/services/File";
import { answerStateTranslation } from "@/services/StateMachine";
import { checkEUConsent } from "@/services/Consent";
import { navigate } from "@/helpers/Navigation";
import { DateTime } from "luxon";
import { sendEmail } from "@/services/commonTools";
import { getUserSub } from "@/services/User";
import { formatServicePriceValue } from "@/helpers/Formatter";

const logger = new Logger("EndUserMyServicesAccount");

@Component({ name: "EndUserMyServicesAccount" })
class EndUserMyServicesAccount extends Vue {
  sortingChoice = "descendingDate";
  // snackbarUpdate = false
  // snackbarTimeout = 2000
  consentOK = "";
  // Data for online booking
  dialogOnlineBooking = false;
  ServiceIDforOnlineBooking = "";
  answerId = "";

  dialogReview = false;
  dialogReviewAnswerId = "";
  dialogReviewReviewId = "";
  dialogReviewspId = "";
  dialogReviewServiceId = "";
  dialogReviewRequestNb = 0;
  dialogReviewAnswerNb = 0;

  dialogCardInfo = false;
  dialogResourceInfo = false;
  resourceFistLastName = "";
  dialogQuestions = false;
  dialogDelete = false;
  dialogDeleteType = 1;
  dialogDeleteAnswerPosition = 0;
  dialogDeleteRequestPosition = 0;
  dialogDescription = false;
  dialogDescriptionTest = "";
  dialogDescriptionType = "";
  deleteItem = [];
  deleteItemAnswer = [];
  questionItem = [];
  request = null;
  businessInfoCpyName = "";
  items = [
    { title: "Click Me 1" },
    { title: "Click Me 2" },
    { title: "Click Me 3" },
    { title: "Click Me 4" },
  ];

  businessInfo = [
    {
      icon: "mdi-map-marker-outline",
      title: "Address",
      text: "1157 av de la plaine<br>06250 Mougins",
    },
    {
      icon: "mdi-cellphone",
      title: "Phone",
      text: "+33   66777",
    },
    {
      icon: "mdi-email",
      title: "Email",
      text: "john@test.com",
    },
    {
      urlLogo: "",
    },
  ];

  businessInfoDefault = [
    {
      icon: "mdi-map-marker-outline",
      title: "Address",
      text: "",
    },
    {
      icon: "mdi-cellphone",
      title: "Phone",
      text: "",
    },
    {
      icon: "mdi-email",
      title: "Email",
      text: "",
    },
    {
      urlLogo: "",
    },
  ];

  created() {
    this.request = new RequestService();
    Promise.all([
      this.request.fetchRequestAndAnswersEU(),
      checkEUConsent(),
    ]).then((result) => {
      this.consentOK = result[1];
      // console.log('this.consentOK', this.consentOK)
    });
  }

  // return true is the request is disabled
  requestDisabled(myRequest) {
    if (myRequest.isFixedPrice === true) {
      return (
        myRequest.stateRequest.isDisabled() ||
        myRequest.answers[0].state.isDisabled()
      );
    } else {
      return myRequest.stateRequest.isDisabled();
    }
  }

  displayQuestions(myRequestObject) {
    if (this.consentOK !== "") {
      // this.snackbarTimeout = 3000
      // this.snackbarUpdate = true
      // alert(this.consentOK)
      this.$root.kdwsnackbar.alert(this.consentOK);
      return;
    }
    // console.log('display Questions')
    this.dialogQuestions = true;
    // console.log(myRequestObject)
    this.questionItem = [...myRequestObject.qna.questions];
    // console.log(this.questionItem)
  }

  deleteRequest(myRequestObject) {
    if (this.consentOK !== "") {
      // this.snackbarTimeout = 3000
      // this.snackbarUpdate = true
      // alert(this.consentOK)
      this.$root.kdwsnackbar.alert(this.consentOK);
      return;
    }
    // console.log('delete request')
    this.dialogDelete = true;
    this.dialogDeleteType = 1;
    // console.log(myRequestObject)
    this.deleteItem.splice(0, this.deleteItem.length);
    this.questionItem.splice(0, this.questionItem.length);
    this.deleteItem.push(myRequestObject);
  }

  deleteAnswer(myAnswer, answerPosition, requestPosition) {
    if (this.consentOK !== "") {
      // this.snackbarTimeout = 3000
      // this.snackbarUpdate = true
      // alert(this.consentOK)
      this.$root.kdwsnackbar.alert(this.consentOK);
      return;
    }
    // console.log('delete answer at position: ' + answerPosition + ' in request number: ' + requestPosition)
    this.dialogDelete = true;
    this.dialogDeleteType = 2;
    this.dialogDeleteAnswerPosition = answerPosition;
    this.dialogDeleteRequestPosition = requestPosition;
    this.deleteItemAnswer.splice(0, this.deleteItemAnswer.length);
    this.deleteItemAnswer.push(myAnswer);
  }

  // This is used to open a dialog box doing several scenarios. It can be used for validateing the OK to approve the proposal.
  validateAnswer(myAnswer, answerPosition, requestPosition) {
    // console.log('delete answer at position: ' + answerPosition + ' in myAnswer:[%j] ', myAnswer)
    this.dialogDelete = true;
    this.dialogDeleteType = 3;
    this.dialogDeleteAnswerPosition = answerPosition;
    this.dialogDeleteRequestPosition = requestPosition;
    this.deleteItemAnswer.splice(0, this.deleteItemAnswer.length);
    this.deleteItemAnswer.push(myAnswer);
  }

  deleteRequestConfirmed() {
    this.dialogDelete = false;
    // console.log('delete request confirmed')
    // console.log(this.dialogDeleteType)
    // console.log('this.deleteItemAnswer')
    // console.log(this.deleteItemAnswer)
    // console.log(myRequestObject)
    switch (this.dialogDeleteType) {
      // We want to delete a request
      case 1:
        console.log("We want to delete this request");
        // console.log(this.deleteItem[0])
        // console.log(this.request)
        this.request.requestCancelledbyEU(this.deleteItem[0]);
        this.deleteItem.splice(0, this.deleteItem.length);
        break;
      // We want to refuse a quotation provided
      case 2:
        this.deleteItemAnswer[0].state
          .refusedQuoteByEU(this.deleteItemAnswer[0])
          .then(() => {
            // console.log(this.request)
            // console.log(this.request.requests[this.dialogDeleteRequestPosition].answers[this.dialogDeleteAnswerPosition].state.currentState)
            // console.log(this.deleteItemAnswer[0].state.currentState)
            this.request.requests[this.dialogDeleteRequestPosition].answers[
              this.dialogDeleteAnswerPosition
            ].state.currentState = this.deleteItemAnswer[0].state.currentState;
            this.deleteItemAnswer.splice(0, this.deleteItemAnswer.length);
          });
        break;
      // We want to validate a quotation and accept the contract
      case 3:
        this.deleteItemAnswer[0].state
          .acceptQuoteByEU(
            this.deleteItemAnswer[0],
            this.request.requests[this.dialogDeleteRequestPosition].serviceId,
          )
          .then(() => {
            // console.log(this.request)
            // console.log(this.request.requests[this.dialogDeleteRequestPosition].answers[this.dialogDeleteAnswerPosition].state.currentState)
            // console.log(this.deleteItemAnswer[0].state.currentState)
            this.request.requests[this.dialogDeleteRequestPosition].answers[
              this.dialogDeleteAnswerPosition
            ].state.currentState = this.deleteItemAnswer[0].state.currentState;
            this.deleteItemAnswer.splice(0, this.deleteItemAnswer.length);
          });
        break;
    }
  }

  cancelDelete() {
    this.dialogDelete = false;
    this.deleteItem.splice(0, this.deleteItem.length);
    this.questionItem.splice(0, this.questionItem.length);
    this.deleteItemAnswer.splice(0, this.deleteItemAnswer.length);
  }

  cancelQuestion() {
    this.dialogQuestions = false;
    this.questionItem.splice(0, this.questionItem.length);
  }

  cancelDialogCardInfo() {
    this.dialogCardInfo = false;
    // this.businessInfo = Object.assign({}, this.businessInfoDefault)
    this.businessInfo = [...this.businessInfoDefault];
    this.businessInfoCpyName = "";
  }

  StopStartSPProposal(myRequest) {
    if (this.consentOK !== "") {
      // this.snackbarTimeout = 3000
      // this.snackbarUpdate = true
      // alert(this.consentOK)
      this.$root.kdwsnackbar.alert(this.consentOK);
      return;
    }
    this.request.StopStartSPProposal(myRequest);
  }

  // 1 for description, 2 for Legal
  openDialogDescription(description, type) {
    if (this.consentOK !== "") {
      // this.snackbarTimeout = 3000
      // this.snackbarUpdate = true
      // alert(this.consentOK)
      this.$root.kdwsnackbar.alert(this.consentOK);
      return;
    }
    this.dialogDescriptionTest = description;
    switch (type) {
      case 1:
        this.dialogDescriptionType = this.$i18n.t("RequestEU.note");
        break;
      case 2:
        this.dialogDescriptionType = this.$i18n.t("RequestEU.legal");
        break;
    }
    this.dialogDescription = true;
  }

  getDialogDescriptionType() {
    if (this.dialogDescriptionNote.text !== "") {
      console.log(this.dialogDescriptionNote);
      return this.dialogDescriptionNote;
    } else {
      return this.dialogDescriptionLegal;
    }
  }

  cancelDialogDescription() {
    this.dialogDescriptionTest = "";
    this.dialogDescriptionType = "";
    this.dialogDescription = false;
  }

  openQuotation(quotationFileName, myRequest) {
    if (this.consentOK !== "") {
      // this.snackbarTimeout = 3000
      // this.snackbarUpdate = true
      // alert(this.consentOK)
      this.$root.kdwsnackbar.alert(this.consentOK);
      return;
    }

    Storage.get(quotationFileName, {
      // level: 'protected',
      download: true,
      // identityId: 'eu-west-1:6b0c0699-c7a0-4584-a16c-69a1048ae237'}, If we want to get it into protected, we will need to retrieve the Identity ID and this is a big headaque. You can read: https://github.com/aws-amplify/amplify-js/issues/2203
      progressCallback(progress) {
        console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
      },
    })
      .then((result) => {
        var date = new Date().toISOString();
        var downloadFileName;
        if (myRequest.projectName !== null) {
          downloadFileName = myRequest.projectName;
        }
        downloadFileName =
          downloadFileName +
          "_" +
          myRequest.serviceName +
          "_" +
          date.substring(0, 16) +
          "_" +
          quotationFileName.substr(111 + FILEHEADERQUOTATION.length - 1);
        downloadBlob(result.Body, downloadFileName);
      })
      .catch((err) => logger.error(err));
  }

  refuseQuote(myAnswer) {
    if (this.consentOK !== "") {
      // this.snackbarTimeout = 3000
      // this.snackbarUpdate = true
      // alert(this.consentOK)
      this.$root.kdwsnackbar.alert(this.consentOK);
      return;
    }
    // console.log('refuse to Quote')
    // console.log('myAnswer')
    // console.log(myAnswer)
    myAnswer.state.refusedQuoteByEU(myAnswer);
  }

  openDialogReview(myAnswer, serviceId, requestNb, answerNb) {
    // console.log('myAnswer:')
    // console.log(myAnswer)
    // console.log('serviceId:')
    // console.log(serviceId)
    this.dialogReviewServiceId = serviceId;
    this.dialogReviewAnswerId = myAnswer.id;
    if (myAnswer && myAnswer.review && myAnswer.review.id) {
      this.dialogReviewReviewId = myAnswer.review.id;
    } else this.dialogReviewReviewId = "";
    if (myAnswer.serviceProvider && myAnswer.serviceProvider.id)
      this.dialogReviewspId = myAnswer.serviceProvider.id;
    this.dialogReviewRequestNb = requestNb;
    this.dialogReviewAnswerNb = answerNb;
    this.dialogReview = true;
  }

  updateRequestListEU(data) {
    console.log("updateRequestListEU");
    console.log(data);
    this.request.updateReviewId(data.requestNb, data.answerNb, data.reviewId);
  }

  resetDialogReview() {
    this.dialogReviewServiceId = "";
    this.dialogReviewAnswerId = "";
    this.dialogReviewReviewId = "";
    this.dialogReviewspId = "";
    this.dialogReviewRequestNb = 0;
    this.dialogReviewAnswerNb = 0;
  }

  openDialogCardInfo(myAnswer) {
    // console.log('I pass here')
    if (this.consentOK !== "") {
      // this.snackbarTimeout = 3000
      // this.snackbarUpdate = true
      // alert(this.consentOK)
      this.$root.kdwsnackbar.alert(this.consentOK);
      return;
    }
    // console.log('openDialogCardInfo')
    // console.log('myAnswer')
    // console.log(myAnswer)
    // Improved below: this.businessInfoCpyName = myAnswer.serviceProvider.namecpy
    if (myAnswer.serviceProvider.namecpy) {
      this.businessInfoCpyName = myAnswer.serviceProvider.namecpy;
    } else {
      this.businessInfoCpyName = "";
    }
    // Improved below: this.businessInfo[0].text = myAnswer.serviceProvider.addresscpy1 + '<br>' + myAnswer.serviceProvider.addresscpy2 + '<br>' + myAnswer.serviceProvider.postcodecpy + ' ' + myAnswer.serviceProvider.postcodecitycpy.townName
    this.businessInfo[0].text = "";
    if (myAnswer.serviceProvider && myAnswer.serviceProvider.addresscpy1) {
      this.businessInfo[0].text =
        this.businessInfo[0].text + myAnswer.serviceProvider.addresscpy1;
    }
    if (myAnswer.serviceProvider && myAnswer.serviceProvider.addresscpy2) {
      this.businessInfo[0].text =
        this.businessInfo[0].text +
        "<br>" +
        myAnswer.serviceProvider.addresscpy2;
    }
    if (myAnswer.serviceProvider && myAnswer.serviceProvider.postcodecpy) {
      this.businessInfo[0].text =
        this.businessInfo[0].text +
        "<br>" +
        myAnswer.serviceProvider.postcodecpy;
    }
    if (
      myAnswer.serviceProvider &&
      myAnswer.serviceProvider.postcodecitycpy &&
      myAnswer.serviceProvider.postcodecitycpy.townName
    ) {
      this.businessInfo[0].text =
        this.businessInfo[0].text +
        " " +
        myAnswer.serviceProvider.postcodecitycpy.townName;
    }
    // Improved below: this.businessInfo[1].text = myAnswer.serviceProvider.phonecpy
    if (myAnswer.serviceProvider && myAnswer.serviceProvider.phonecpy) {
      this.businessInfo[1].text = myAnswer.serviceProvider.phonecpy;
    } else {
      this.businessInfo[1].text = "";
    }
    // Imporved below: this.businessInfo[2].text = myAnswer.serviceProvider.emailcpy
    if (myAnswer.serviceProvider && myAnswer.serviceProvider.emailcpy) {
      this.businessInfo[2].text = myAnswer.serviceProvider.emailcpy;
    } else {
      this.businessInfo[2].text = "";
    }
    this.dialogCardInfo = true;
    // console.log('openDialogCardInfo')
    // console.log('myAnswer')
    // console.log(myAnswer)
    // Duplicated lines: this.businessInfoCpyName = myAnswer.serviceProvider.namecpy
    // Duplicated lines: this.businessInfo[0].text = myAnswer.serviceProvider.addresscpy1 + '<br>' + myAnswer.serviceProvider.addresscpy2 + '<br>' + myAnswer.serviceProvider.postcodecpy + ' ' + myAnswer.serviceProvider.postcodecitycpy.townName
    // Duplicated lines: this.businessInfo[1].text = myAnswer.serviceProvider.phonecpy
    // Duplicated lines: this.businessInfo[2].text = myAnswer.serviceProvider.emailcpy
    if (
      myAnswer.serviceProvider.logocpy !== null ||
      myAnswer.serviceProvider.logocpy !== undefined
    ) {
      if (myAnswer.serviceProvider.logocpy !== "") {
        // console.log(myAnswer.serviceProvider.logocpy)
        Storage.get(myAnswer.serviceProvider.logocpy, { level: "public" })
          .then((result) => {
            this.businessInfo[3].urlLogo = result;
            // console.log('result of the url we got from the storage:')
            // console.log(result)
            this.dialogCardInfo = true;
          })
          .catch((err) => logger.error(err));
      } else {
        this.dialogCardInfo = true;
      }
    } else {
      this.dialogCardInfo = true;
    }
  }

  getDeleteDialogText() {
    switch (this.dialogDeleteType) {
      // case 1: return this.$i18n.t('RequestEU.cancelRequest')
      // case 2: return this.$i18n.t('RequestEU.refuseQuote')
      // case 3: return this.$i18n.t('RequestEU.validateQuote')
      case 1:
        return "RequestEU.cancelRequest";
      case 2:
        return "RequestEU.refuseQuote";
      case 3:
        return "RequestEU.validateQuote";
      default:
        return "Case not planned";
    }
  }

  formatFixedPrice(fixedPrice, price) {
    // {{ (myRequest.isFixedPrice === true) ? 'Prix fixe (' + myRequest.answers[0].servicePrice + '€)': $t('RequestEU.fixedPrice') }}
    var value;
    if (fixedPrice === true) {
      value = this.$i18n.t("hero.fixedPrice");
      value = value + price + "€)";
    } else {
      value = this.$i18n.t("hero.quotationBased");
    }
    return value;
  }

  navigateSearch() {
    navigate(this, "Home"); // goes Home, hero page
  }

  navigateMyAccount() {
    navigate(this, "EndUserMyAccount"); // goes Home, hero page
  }

  sortingChoiceChanged() {
    console.log("sorting choice changed:" + this.sortingChoice);
    switch (this.sortingChoice) {
      case "ascendingDate":
        this.request.sortAscendingRequest();
        break;
      case "descendingDate":
        this.request.sortDescendingRequest();
        break;
      case "category":
        this.request.sortCategoryRequest();
        break;
      default:
        this.request.sortDescendingRequest();
        break;
    }
  }

  convertStateName(originalName) {
    return answerStateTranslation("a_" + originalName);
  }

  openDialogResourceInfo(myRequest) {
    this.dialogResourceInfo = true;
    this.resourceFistLastName =
      myRequest.resourceFirstname + " " + myRequest.resourceLastname;
  }

  addAppointment(myRequest) {
    // console.log('addAppointment')
    // console.log('from appointment 1:', this.dialogOnlineBooking)
    this.ServiceIDforOnlineBooking = myRequest.serviceId;
    this.answerId = myRequest.answers[0].id;
    this.dialogOnlineBooking = true;
    // console.log('from appointment 2:', this.dialogOnlineBooking)
  }

  // This function is trigered by the Booking component, once it is clicked on validate.
  // It is used to refresh the answer with the booking detail.
  // The argument is the answerId, where the appointment data are contained.
  euAnswerUpdateFromBooking(answerID) {
    // console.log('euAnswerUpdateFromBooking')
    this.request.refreshAppointmentDateAnswer(answerID);
  }

  deleteAppointment(myRequest, index) {
    // console.log('deleteAppointment: ', index)
    // console.log(myRequest)
    var appointmentDateTimeISO = myRequest.appointmentDateTimeISO;
    var resourceID = myRequest.resourceID;
    // console.log('appointmentDateTime from source:', myRequest.appointmentDateTimeISO)
    // console.log('appointmentDateTime:', appointmentDateTimeISO)
    this.request.deleteAppointment(myRequest, index).then((result) => {
      // console.log(result)
      // sending appointment deleted to Resource
      var params = {
        serviceName: myRequest.serviceName,
        date: DateTime.fromISO(appointmentDateTimeISO).toFormat("yyyy-LL-dd"),
        time: DateTime.fromISO(appointmentDateTimeISO).toFormat("HH:mm"),
        resourceId: resourceID,
        sendTo: "RE",
      };
      // console.log(params)
      sendEmail("bookingAppointmentDeletedResource", params);
      // We want to send a cancelation request to the end user.
      getUserSub().then((enduserId) => {
        params.enduserId = enduserId;
        params.sendTo = "EU";
        // console.log('params2:')
        // console.log(params)
        sendEmail("bookingAppointmentDeletedResource", params);
      });
    });
  }

  formatServicePriceValueInt(priceValue) {
    return formatServicePriceValue(priceValue, "fr");
  }
}

export default EndUserMyServicesAccount;
</script>
